import React from 'react';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme(); // Access the Material-UI theme
  const footerStyles = {
    position: 'fixed', // You can use 'fixed' or 'absolute' based on your preference
    bottom: 0,
    maxWidth: '85%',
    width: '100%',
    color: '#fff', // Text color
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontFamily: "'Sacramento', cursive", // Apply the secondary font
    fontSize: '22px',
    fontWeight: 'bold',
  };
  // const footerStyles = {
  //   backgroundColor: theme.palette.secondary.main,
  //   color: theme.palette.background.main,
  //   padding: '20px',
  //   fontFamily: 'Sacramento, cursive, "Lucida Handwriting", cursive !important', // Apply the secondary font
  // };
  return (
    <footer style={footerStyles}>
      <div >
         &copy; 2023 Thezyrie. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ConstructionIcon from '@mui/icons-material/Construction';

export function AcademiaPage() {

  const cardStyles = {
    //background: `linear-gradient(to bottom,hsl(340, 100%, 95%), #f0ece7)`
    //background: `linear-gradient(to bottom,#FFEBF9, #f0ece7)`,
    backgroundColor: '#f0ece7',
    border: '1px solid #5a5451',
  };
  const publicationCardStyles = {
    //background: `linear-gradient(to bottom,hsl(340, 100%, 95%), #f0ece7)`
    //background: `linear-gradient(to bottom,#FFEBF9, #f0ece7)`,
    backgroundColor: '#f0ece7',
    borderLeft: '1px solid #5a5451',
    borderRight: '1px solid #5a5451',
    borderTop: '1px solid #5a5451',
  };
  const cardContentStyles = {
    textAlign: 'left', // Align the text content to the left
  };
  const publications = [
    {
      title: 'Finding and Categorizing COVID-19 Papers in CS Education',
      author: 'Brian Harrington, Aditya Kulkarni, Zixiao Ren, Conroy Trinh, Raha Gharadaghi, Thezyrie Amarouche, Ansh Aneel, Anand Karki, Seemin Syed, and David Yue',
      date: '2023',
      url: 'https://doi.org/10.1145/3545947.3576288',
      source: 'In Proceedings of the 54th ACM Technical Symposium on Computer Science Education V. 2 (SIGCSE 2023). Association for Computing Machinery, New York, NY, USA, 1342.', // Journal name
      conference: 'SIGCSE 2023'
    },
    {
      title: 'Promptly: Using Prompt Problems to Teach Learners How to Effectively Utilize AI Code Generators (PREPRINT)',
      author: 'Paul Denny, Juho Leinonen, James Prather, Andrew Luxton-Reilly, Thezyrie Amarouche, Brett A. Becker, Brent N. Reeves',
      date: '2023',
      url: 'https://arxiv.org/abs/2307.16364',
      conference: 'SIGCSE 2024 (pending)'
    },
  ]
  return (
    <div>
      <h3>Education</h3>
      <Card style={cardStyles} elevation={0}>
        <CardContent style={cardContentStyles}>
            <Typography variant="h5" component="div">
              Honours Bachelor of Science from the University of Toronto 2018
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
             Computer Science Specialist - Information Systems
            </Typography>
            
        </CardContent>
      </Card>
      <h3>Publications</h3>
      {publications.map((publication, index) => (
        <Card key={index} style={publicationCardStyles} elevation={0}>
          <CardContent style={cardContentStyles}>
            <Typography variant="h5" component="div">
              {publication.title}
            </Typography>
            
            <Typography variant="subtitle1" color="text.secondary">
              {publication.author} ({publication.date}). {publication.source}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Conference: {publication.conference}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              <a href={publication.url} target="_blank" rel="noopener noreferrer">
                {publication.url}
              </a>
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Card style={publicationCardStyles} elevation={0}>
          <CardContent style={cardContentStyles}>
            <Typography variant="h5" component="div" sx={{textAlign: 'center'}}>
             More coming soon!
            <br/>
            <ConstructionIcon sx={{fontSize: '4rem', marginBottom: '10px'}} /> 
            </Typography>
            
          </CardContent>
        </Card>
    </div>
  )
}
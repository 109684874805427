import { ThemeProvider } from '@mui/material/styles';
import customTheme from './customTheme';
import './App.css';
import DrawerAppBar from './DrawerAppBar';
import {
  BrowserRouter as Router,
  Routes,
  Route

} from "react-router-dom";
import { HomePage } from './HomePage';
import { AcademiaPage } from './AcademiaPage';
import Footer from './Footer'; // Import your Footer component

function App() {
  const containerStyles = {
    maxWidth: '85%', // Set the desired maximum width for the content
    margin: '0 auto', // Center the content horizontally
    maxHeight: '90vh', // Adjust the height as needed
    overflowY: 'auto', // Enable vertical scrolling when content overflows
    marginBottom: '50px'
  };

  return (
    <div className="App" style={containerStyles}>
      <ThemeProvider theme={customTheme}>
        <Router>
          <DrawerAppBar />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/academia" element={<AcademiaPage />} />
          </Routes>
          <Footer /> {/* Include the Footer component here */}
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

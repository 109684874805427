// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAa3xTI4PTOOyUTRWaOn5H46yWNukUfpVA",
  authDomain: "thezyrie-d585c.firebaseapp.com",
  projectId: "thezyrie-d585c",
  storageBucket: "thezyrie-d585c.appspot.com",
  messagingSenderId: "1080544542505",
  appId: "1:1080544542505:web:1243cee369aacda55cc138",
  measurementId: "G-PC14D9HZSL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

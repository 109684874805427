// customTheme.js

import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    typography: {
        fontFamily: 'Cutive Mono, sans-serif', // Primary font
        h1: {
            fontFamily: 'Sacramento, cursive', // Secondary font for headings (h1)
        },
        h2: {
            fontFamily: 'Sacramento, cursive', // Secondary font for headings (h2)
        },
        // Define additional styles and fonts as needed
    },
    palette: {
        primary: {
            main: '#b6d5ec', // Ocean-Inspired Blue
        },
        secondary: {
            main: '#FFC0CB', // Soft Pink
            secondary: '#FFCCBF', // Softer salmon Pink
        },
        // error: {
        //     main: '#E0E0E0', // Pale Gray
        // },
        background: {
            default: '#f0ece7', // Cream/Ivory
        },
        text: {
            primary: '#5a5451', // Muted Gray-Chocolate for the text color
        },
    },
});

export default customTheme;

import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export function HomePage() {
  const theme = useTheme(); // Access the Material-UI theme
  const secondaryColor = theme.palette.secondary.main; // Get the secondary color
  const backgroundColorColor = theme.palette.background.main; // Get the secondary color

  const cardStyles = {
    //background: `linear-gradient(to bottom,hsl(340, 100%, 95%), #f0ece7)`
    // background: `linear-gradient(to bottom,#FFEBF9, #f0ece7)`
    backgroundColor: '#f0ece7',
    border: '1px solid #5a5451',
    marginBottom: '30px'
  };
  const cardContentStyles = {
    textAlign: 'left', // Align the text content to the left
  };
  return (
    <div>
      <Card style={cardStyles} elevation={0}>
        <CardContent style={cardContentStyles}>
          {<div>

            My name is Thezyrie. I am an ABC ( Amazigh Algerian born Canadian )
            <br />

            Fun Fact: Thezyrie, derived from Tiziri in Amazigh, means moonlight
            <br />
            <br />
            My journey in the realm of Computer Science started in 2011 when I took a computer science course in high school after my computer got hacked and losing years of family pictures. Heartbroken, I vowed to one day recover them. Up to this day I have the computer in my garage. Never recovered any of it but I kept it :')

            <br />
            <br />
            A small non-exclusive list of my interests:
            <ul>
              <li><Link to="/academia">research in Computer Science Education</Link></li>
              <li>research in Robotics/Embedded Systems</li>
              <li>environmental and conservation initiatives</li>
            </ul>

            <a href='https://www.linkedin.com/in/thezyrie/' target="_blank" rel="noopener noreferrer">
              Connect with me on LinkedIn!
            </a>
          </div>}
        </CardContent>
      </Card>
      <Typography variant="subtitle1" color="secondary.main" sx={{ fontFamily: "'Sacramento', cursive", fontSize: '32px', fontWeight: 'bold' }}>
        In loving memory of mellow, my loved siamese. The colours are inspired by her beauty.
      </Typography>
    </div>

  );
}